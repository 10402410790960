import React, { useCallback, useEffect, useRef, useState } from 'react'
import Paths from '~routes/paths'
import BurgerButton from '../BurgerButton'
import * as Styled from './Navigation.styles'
import { useAuth } from '~components/AuthProvider'

const Navigation: React.FC = () => {
  const { user: currentUser } = useAuth()
  const navRef = useRef<HTMLDivElement>(null)
  // This will prevent lagging when fast clicking to the button
  const isToggling = useRef<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleMenu = useCallback(() => {
    if (!isToggling.current) {
      setIsOpen(prevState => {
        isToggling.current = true
        return !prevState
      })
    }
  }, [setIsOpen])

  useEffect(() => {
    isToggling.current = false
  }, [isOpen])

  return (
    <>
      <BurgerButton isOpen={isOpen} onClick={handleToggleMenu} />

      <Styled.NavContainer ref={navRef} isOpen={isOpen}>
        <Styled.Wrapper>
          <Styled.List>
            <Styled.Item>
              <Styled.Link to={Paths.projects}>Projects</Styled.Link>
            </Styled.Item>

            <Styled.Item>
              <Styled.Link to={Paths.organizations}>Organizations</Styled.Link>
            </Styled.Item>

            <Styled.Item>
              <Styled.Link to={Paths.consortiums}>Consortiums</Styled.Link>
            </Styled.Item>

            {currentUser && (
              <Styled.Item>
                <Styled.Link to={Paths.datasets}>Datasets</Styled.Link>
              </Styled.Item>
            )}

            {/* <Styled.Item> */}
            {/*  <Styled.Link to={Paths.featured}>Insights</Styled.Link> */}
            {/* </Styled.Item> */}
          </Styled.List>
          <Styled.List>
            <Styled.Item>
              <Styled.Link to={Paths.apiSettings}>API</Styled.Link>
            </Styled.Item>
            <Styled.Item>
              <Styled.Link to={Paths.tokens}>Token</Styled.Link>
            </Styled.Item>
            {/* <Styled.Item> */}
            {/*  <Styled.HiringLink as="a" href="https://hacera.bamboohr.com/jobs" target="_blank" rel="noopener noreferrer"> */}
            {/*    We&apos;re Hiring! */}
            {/*  </Styled.HiringLink> */}
            {/* </Styled.Item> */}
          </Styled.List>
        </Styled.Wrapper>
      </Styled.NavContainer>
    </>
  )
}

export default Navigation
