const Paths = {
  landing: '/',
  hackEventRoute: '/pof',
  wallet: '/wallet',
  projects: '/projects',
  projectDetails: '/projects/:slug/*',
  consortiums: '/consortiums',
  consortiumDetails: '/consortiums/:slug/*',
  organizations: '/organizations',
  organizationDetails: '/organizations/:slug/*',
  datasets: '/datasets',
  datasetsNew: '/datasets/new',
  datasetDetails: '/datasets/:id/*',
  contact: '/contact',
  termsOfUse: '/terms',
  privacyPolicy: '/privacy',
  about: '/about',
  services: '/services',
  solutions: '/solutions',
  partners: '/partners',
  loggedIn: '/logged-in',
  FAQ: '/faq',
  featured: '/insights',
  featuredDetails: '/insights/:slug',
  notifications: '/notifications',
  apiSettings: '/our-api',
  tokens: '/token',
}

export default Paths
