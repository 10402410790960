import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { Redirect } from 'react-router6-redirect'
import Paths from '~routes/paths'
import LandingPage from '~routes/LandingPage'
import { RequireAuth } from '../components/AuthProvider'

const WalletPage = lazy(() => import('~routes/WalletPage'))
const ProjectsListPage = lazy(() => import('~routes/Projects/ProjectsListPage'))
const ProjectDetailsPage = lazy(() => import('~routes/Projects/ProjectDetailsPage'))
const ConsortiumsListPage = lazy(() => import('~routes/Consortiums/ConsortiumsListPage'))
const ConsortiumDetailsPage = lazy(() => import('~routes/Consortiums/ConsortiumDetailsPage'))
const OrganizationsListPage = lazy(() => import('~routes/Organizations/OrganizationsListPage'))
const OrganizationDetailsPage = lazy(() => import('~routes/Organizations/OrganizationDetailsPage'))
const DatasetsListPage = lazy(() => import('~routes/Datasets/DatasetsListPage'))
const NewDataset = lazy(() => import('~routes/Datasets/NewDatasetPage'))
const DatasetDetailsPage = lazy(() => import('~routes/Datasets/DatasetDetailsPage'))
const AboutUsPage = lazy(() => import('~routes/AboutUsPage'))
const ServicesPage = lazy(() => import('~routes/ServicesPage'))
const SolutionsPage = lazy(() => import('~routes/SolutionsPage'))
const PartnersPage = lazy(() => import('~routes/PartnersPage'))
const TermsOfUsePage = lazy(() => import('~routes/TermsOfUsePage'))
const PrivacyPolicyPage = lazy(() => import('~routes/PrivacyPolicyPage'))
const FAQPage = lazy(() => import('~routes/FAQPage'))
const ContactPage = lazy(() => import('~routes/ContactPage'))
const LoggedInPage = lazy(() => import('~routes/LoggedInPage'))
const FeaturedPage = lazy(() => import('~routes/Featured/FeaturedListPage'))
const FeaturedDetailsPage = lazy(() => import('~routes/Featured/FeaturedDetailsPage'))
const Notifications = lazy(() => import('~routes/NotificationsPage'))
const APISettingsPage = lazy(() => import('~routes/APISettingsPage'))
const TokensPage = lazy(() => import('~routes/TokensPage'))

export const routes: RouteObject[] = [
  { path: Paths.landing, element: <LandingPage /> },
  { path: Paths.hackEventRoute, element: <Redirect to={Paths.landing} /> },
  { path: Paths.wallet, element: <Redirect to={Paths.landing} /> },
  { path: Paths.projects, element: <ProjectsListPage /> },
  { path: Paths.projectDetails, element: <ProjectDetailsPage /> },
  { path: Paths.consortiums, element: <ConsortiumsListPage /> },
  { path: Paths.consortiumDetails, element: <ConsortiumDetailsPage /> },
  { path: Paths.organizations, element: <OrganizationsListPage /> },
  { path: Paths.organizationDetails, element: <OrganizationDetailsPage /> },
  { path: Paths.tokens, element: <TokensPage /> },
  {
    path: Paths.datasets,
    element: (
      <RequireAuth>
        <DatasetsListPage />
      </RequireAuth>
    ),
  },
  {
    path: Paths.datasetsNew,
    element: (
      <RequireAuth>
        <NewDataset />
      </RequireAuth>
    ),
  },
  {
    path: Paths.datasetDetails,
    element: (
      <RequireAuth>
        <DatasetDetailsPage />
      </RequireAuth>
    ),
  },
  { path: Paths.about, element: <Redirect to={Paths.landing} /> },
  { path: Paths.services, element: <Redirect to={Paths.landing} /> },
  { path: Paths.solutions, element: <Redirect to={Paths.landing} /> },
  { path: Paths.partners, element: <Redirect to={Paths.landing} /> },
  { path: Paths.termsOfUse, element: <TermsOfUsePage /> },
  { path: Paths.privacyPolicy, element: <PrivacyPolicyPage /> },
  { path: Paths.FAQ, element: <FAQPage /> },
  { path: Paths.contact, element: <ContactPage /> },
  { path: Paths.featured, element: <Redirect to={Paths.landing} /> },
  { path: Paths.featuredDetails, element: <Redirect to={Paths.landing} /> },
  { path: `${Paths.loggedIn}/*`, element: <LoggedInPage /> },
  {
    path: `${Paths.notifications}/*`,
    element: <Redirect to={Paths.landing} />,
  },
  { path: Paths.apiSettings, element: <APISettingsPage /> },
]

export default routes
